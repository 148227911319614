.btn-add-operation {
  font-size: 28px;
  width: 48px !important;
  height: 48px;
  float: right;
}

h1 {
  font-size: 25px;
  margin: 0;
  text-align: center;
  color: white;
}

.title-row {
  padding: 16px 24px;
}

.modify-btns {
  float: right;
  width: 100%;
  right: 28px;
  position: absolute;
}

.col-contract-details {
  width: 100%;
}

@media (max-width: 768px) {
  .col-contract-details {
    width: 60%;
  }
}

.image-container {
  position: relative;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  transition: border 0.3s ease;
}

.image-container.selected {
  outline: 1px solid red;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.image-container.deselected {
  outline: 1 px solid transparent;
}

.checkbox {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  z-index: 1;
}

.image-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-button {
  margin-bottom: 10px;
  visibility: hidden;
  background-color: #d92210;
  color: white;
}

.delete-button.visible {
  visibility: visible;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
